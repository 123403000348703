/*
 * @Author: wangqs 
 * @description 测试模块
 * @Date: 2021-07-11 16:24:29 
 * @Last Modified by: wangqs
 * @Last Modified time: 2021-08-04 23:54:36
 */
<template>
  <van-popup
    v-model="showArea"
    position="bottom"
  >
    <van-area
      :value="form.areaNo || ''"
      :area-list="areaList"
      @confirm="onConfirmCity"
      @cancel="showArea = false"
    />
  </van-popup>
</template>

<script>
import { areaList } from '@vant/area-data';
export default {
  name: 'Test',
  components: {
  },
  data () {
    return {
      areaList,
      showArea: false,
      defaultAreaNo: '',
      addrStr: '',
      form: {
        provinceNo: '',
        cityNo: '',
        areaNo: '',
        provinceName: '',
        cityName: '',
        areaName: ''
      },
      index: 0
    }
  },
  computed: {
  },
  created () {
  },
  methods: {
    show (item, index) {
      this.form = item
      this.index = index
      this.showArea = true
    },
    onConfirmCity (values) {
      console.log(values, 'sss')
      this.addrStr = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join('/');
      this.form.provinceNo = values[0].code
      this.form.cityNo = values[1].code
      this.form.areaNo = values[2].code
      this.form.provinceName = values[0].name
      this.form.cityName = values[1].name
      this.form.areaName = values[2].name
      this.showArea = false;
      this.$emit('cityChange', this.form, this.index)
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
