/*
 * @Author: wangqs 
 * @description 机构入驻
 * @Date: 2021-07-11 16:24:29 
 * @Last Modified by: wangqs
 * @Last Modified time: 2022-01-22 22:43:11
 */
<template>
  <div class="page-box box">
    <div class="img-box box">
      <van-image :src="VUE_APP_STATIC_URL + 'settled-bg.png'"></van-image>
    </div>
    <div class="first-box form-box box">
      <van-field
        v-model="form.orgName"
        required
        label="机构名称"
        placeholder="请输入机构名称"
      />

      <van-field
        readonly
        required
        clickable
        name="area"
        :value="addrStr"
        label="机构地址"
        placeholder="点击选择省市区"
        @click="showArea = true"
      />
      <van-popup
        v-model="showArea"
        position="bottom"
      >
        <van-area
          :value="form.areaNo || ''"
          :area-list="areaList"
          @confirm="onConfirmCity"
          @cancel="showArea = false"
        />
      </van-popup>

      <van-field
        v-model="form.detailAddr"
        required
        label="详细地址"
        placeholder="请输入校区详细地址"
      />

      <van-field
        v-model="form.legalName"
        required
        label="法定代表人"
        placeholder="请输入法定代表人"
      />
      <van-field
        v-model="form.tel"
        required
        type="number"
        label="联系电话"
        placeholder="请输入联系电话"
      />

      <van-field
        v-model="form.brandName"
        required
        label="品牌名"
        placeholder="请输入品牌名"
      />

      <van-field
        v-model="form.contractName"
        required
        label="联系人姓名"
        placeholder="请输入联系人姓名"
      />

      <van-field
        v-model="form.contractMobile"
        required
        type="number"
        label="联系人电话"
        placeholder="请输入联系人电话"
      />

      <!-- 校区信息 -->
      <div class="school-box box">
        <!-- schoolList -->
        <van-field
          v-model="sms"
          required
          style="padding-right: 2px;"
          readonly
          label="学校信息"
          placeholder=""
        >
          <template #button>
            <van-button
              icon="plus"
              plain
              size="mini"
              type="primary"
              @click="addSchool"
            >
              添加校区
            </van-button>
          </template>
        </van-field>
        <template v-for="(item, index) in form.schoolList || []">
          <div
            :key="index"
            class="school-item box"
          >
            <van-icon
              v-show="index != 0"
              class="delete-icon"
              color="#f00"
              name="clear"
              @click="deleteSchool(index)"
            />
            <van-field
              v-model="item.schoolName"
              required
              label="校区名"
              placeholder="请输入校区名"
            />
            <van-field
              readonly
              required
              clickable
              name="area"
              :value="item.addrStr"
              label="校区地址"
              placeholder="点击选择省市区"
              @click="showAreaFun(item, index)"
            />
            <select-addr
              ref="selectAddr"
              @cityChange="cityChange"
            ></select-addr>
            <van-field
              v-model="item.detailAddr"
              required
              label="校区地址"
              placeholder="请输入校区地址"
            />
          </div>
          <van-divider
            :key="'divider'+ index"
            style="margin:0;"
          />
        </template>
      </div>
    </div>

    <div
      class="form-box box"
      style="margin-top: 10px;"
    >
      <van-field
        v-model="form.bankAccountName"
        required
        label="开户人"
        placeholder="请输入开户人名称"
      />

      <van-field
        v-model="form.bankAccountNo"
        required
        label="银行卡号"
        placeholder="请输入银行卡号"
      />

      <van-field
        v-model="form.bankName"
        required
        label="开户行"
        placeholder="请输入开户行"
      />

      <div
        class="box upload-out-box"
      >
        <div
          class="upload-box"
        >
          <div class="title">
            <span style="color: #D5423E;">*</span>
            营业执照照片
          </div>
          <div class="upload-btn">
            <van-uploader
              v-model="licenceUrl"
              :max-count="1"
              :after-read="(query)=>{afterRead(query, 'licenceUrl')}"
              :max-size="20 * 1024 * 1024"
              @oversize="onOversize"
              @delete="(e)=>{deleteItem(e, 'licenceUrl')}"
            >
            </van-uploader>
          </div>
        </div>
      </div>
    </div>

    <!-- 提交 -->
    <van-button
      class="submit-btn"
      color="#51B464"
      round
      type="info"
      @click="submit"
    >
      提交
    </van-button>
  </div>
</template>

<script>
import { areaList } from '@vant/area-data';
import SelectAddr from '@components/common/addrSelect.vue'
export default {
  name: 'Settled',
  components: {
    SelectAddr
  },
  data () {
    return {
      sms: '',
      licenceUrl: [],
      formObj: {
        orgName: {
          name: '机构名称'
        },
        provinceNo: {
          name: '机构地址'
        },
        detailAddr: {
          name: '机构详细地址'
        },
        tel: {
          name: '联系电话'
        },
        brandName: {
          name: '品牌名'
        },
        contractMobile: {
          name: '联系人电话'
        },
        contractName: {
          name: '联系人姓名'
        },
        legalName: {
          name: '法人姓名'
        },
        schoolList: {
          name: '学校信息'
        },
        bankAccountName: {
          name: '开户人名称'
        },
        bankAccountNo: {
          name: '开户行号码'
        },
        bankName: {
          name: '开户行名称'
        },
        licenceUrl: {
          name: '营业执照'
        },
        schoolList: {
          name: '学校信息'
        }
      },
      form: {
        orgName: '',
        schoolList: [
          {
            detailAddr: '',
            areaNo: '',
            cityNo: '',
            addrStr: '',
            provinceNo: '',
            schoolName: ''
          }
        ]
      },
      schoolItem: {
        detailAddr: '',
        areaNo: '',
        cityNo: '',
        addrStr: '',
        provinceNo: '',
        schoolName: ''
      },
      showArea: false,
      addrStr: '',
      radio: '',
      areaList,
      orgInfo: {}
    }
  },
  computed: {
  },
  created () {
    this.getOrgInfo()
  },
  methods: {
    // 入住前信息
    async getOrgInfo () {
      let res = await this.$http.orgApi.beforeOrgInfo({})
      console.log(res, 'res=')
      if (res.code == 200) {
        this.orgInfo = res.msg || {}
        if (this.orgInfo.bindCard === 0) {
          this.$dialog.alert({
            title: '未绑定银行卡，请前往个人中心绑定银行卡',
            theme: 'round-button',
          }).then(() => {
            // on close
            this.$router.back()
          });
        } else if (this.orgInfo.settled == 1) {
          if (this.orgInfo.contractUrl) {
            // 去签署
            window.localStorage.setItem('pdfOrder', JSON.stringify(this.orgInfo))
            this.$router.replace('/order/sign?type=orgSettled')
          } else {
            this.$dialog.alert({
              title: '入驻申请中，请耐心等待',
              message: '客服人员会尽快与您取得联系，请保持手机畅通',
              theme: 'round-button',
            }).then(() => {
              // on close
              this.$router.back()
            });
          }
        }
      }
    },
    // 表单校验
    filterFrom () {
      for (let j = 0; j < (this.form.schoolList || []).length; j++) {
        let item = this.form.schoolList[j]
        // if ((!item.detailAddr && !item.schoolName && !item.provinceNo)) {
        //   this.formObj.schoolList.splice(j, 1)
        //   break
        // }
        console.log(item, 'pppp')
        if ((!item.detailAddr || !item.schoolName || !item.provinceNo)) {
          this.$toast('请完善学校信息')
          return false
        }
      }
      for (let i in this.formObj || {}) {
        if (!this.form[i]) {
          this.$toast('请完善' + this.formObj[i].name)
          return false
        }
      }
      return true
    },
    // 表单提交
    async submit () {
      if (this.filterFrom()) {
        let res = await this.$http.orgApi.orgSettled(this.form)
        console.log(res, 'res')
        if (res.code === 200) {
          this.$dialog.alert({
            title: '申请成功',
            message: '客服人员会尽快与您取得联系，请保持手机畅通',
            theme: 'round-button',
          }).then(() => {
            // on close
            this.$router.back()
          });
        }
      }
    },
    afterRead (file, key) {
      console.log(file)
      let vm = this;
      var fd = new FormData()
      // fd.append('Content-Type', 'multipart/form-data')
      fd.append('multipartFile', file.file)
      fd.append('dir', 'org')
      vm.$toast.loading({
        message: '加载中',
        forbidClick: true,
        duration: 0
      });
      var xhr = new XMLHttpRequest()
      xhr.open('POST', '/gateway/img/upload', true)
      xhr.onload = function () {
        if (Math.floor(xhr.status / 100) === 2) {
          var res = JSON.parse(xhr.response)
          if (res.code === 200) {
            console.log(res, '上传成功')
            // file.url = res.msg.url || ''
            vm.form[key] = res.msg.url || ''
          } else {
            vm.$toast.clear()
            vm.$toast('上传失败，请检查后重新上传')
          }
        } else {
          // clearInterval(vm.timerClock)
          vm.$toast.clear()
          vm.$toast('上传失败')
        }
        vm.$toast.clear()
      }
      xhr.onerror = function () {
        vm.$toast.clear()
        vm.$toast.fail('文件 ' + file.name + ' 上传失败')
      }
      xhr.setRequestHeader('token', JSON.parse(window.localStorage.getItem('userData') || '{}').token)
      xhr.send(fd)
    },
    onOversize (file) {
      console.log(file);
      this.$toast('文件大小不能超过 2M');
    },
    deleteItem (e, key) {
      console.log(e, key)
    },
    onConfirmCity (values) {
      console.log(values, 'sss')
      this.addrStr = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join('/');
      this.form.provinceNo = values[0].code
      this.form.cityNo = values[1].code
      this.form.areaNo = values[2].code
      this.form.provinceName = values[0].name
      this.form.cityName = values[1].name
      this.form.areaName = values[2].name
      this.showArea = false;
    },
    showAreaFun (item, index) {
      this.$refs.selectAddr[index].show(item, index)
    },
    cityChange (item, index) {
      console.log(item, index)
      this.form.schoolList[index].provinceNo = item.provinceNo
      this.form.schoolList[index].cityNo = item.cityNo
      this.form.schoolList[index].areaNo = item.areaNo
      if (item.provinceNo) {
        this.form.schoolList[index].addrStr = `${item.provinceName}/${item.cityName}/${item.areaName}` 
      }
      console.log(item, index, this.form.schoolList[index])
    },
    // 添加校区
    addSchool () {
      let item = this.$deepClone(this.schoolItem)
      this.form.schoolList.push(item)
    },
    // 删除校区
    deleteSchool (index) {
      this.form.schoolList.splice(index, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.page-box {
  padding-bottom: 60px;
  padding-bottom: calc(60px + constant(safe-area-inset-bottom)); ///兼容 IOS<11.2/
  padding-bottom: calc(60px + env(safe-area-inset-bottom)); ///兼容 IOS>11.2/
  background: #f5f5f5;
  .img-box {
    height: 215px;
    .van-image {
      width: 100%;
      height: 100%;
    }
  }
  .form-box {
    background: #fff;
    padding: 0 15px;
  }
  .first-box {
    padding-top: 12px;
    border-radius: 6px 6px 0 0;
    margin-top: -6px;
  }
  .upload-out-box {
    padding: 10px 8px 0;
    .title {
      margin-bottom: 10px;
    }
  }
  .submit-btn {
    width: 346px;
    position: fixed;
    bottom: 20px;
    bottom: calc(10px + constant(safe-area-inset-bottom)); ///兼容 IOS<11.2/
    bottom: calc(10px + env(safe-area-inset-bottom)); ///兼容 IOS>11.2/
    left: 50%;
    margin-left: -173px;

  }
  .school-box {
    .school-item {
      // margin-bottom: 10px;
      padding-left: 30px;
      .delete-icon {
        font-size: 20px;
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -10px;
      }

    }
  }
}
</style>
